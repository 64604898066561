/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  --hue: 240;
  --first-color: hsl(var(--hue), 16%, 18%);
  --first-color-alt: hsl(var(--hue), 16%, 12%);
  --title-color: hsl(var(--hue), 8%, 15%);
  --text-color: hsl(var(--hue), 8%, 35%);
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #FFF;

  /*========== Font and typography ==========*/
  --body-font: 'Poppins', sans-serif;
  --big-font-size: 1.5rem;
  --normal-font-size: .938rem;

  /*========== z index ==========*/
  --z-modal: 1000;
}

@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 1.75rem;
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
button{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body{
  background-color: var(--body-color);
  color: var(--text-color);
  position: relative;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

/*=============== MODAL ===============*/
.container{
  margin-left: 1rem;
  margin-right: 1rem;
  
}

.modal{
  height: 100vh;
  display: grid;
  place-items: center;
}

.modal__button{
  display: inline-block;
  background-color: var(--first-color);
  color: #FFF;
  padding: 1rem 1.25rem;
  border-radius: .5rem;
  transition: .3s;
}

.modal__button:hover{
  background-color: var(--first-color-alt);
}

.modal__container{
  top: 0;
  left: 0;
  background-color: hsla(var(--hue), 18%, 75%, .8);
  width: 100%;
  height: 100%;
  display: grid;
  align-items: flex-end;
  transition: all .3s;
  z-index: var(--z-modal);
  opacity: 0;

  /*=== Effect 3 ===*/
  /* perspective: 1000px; */
}

.modal__content{
  float: right;
  margin: auto;
  position: relative;
  background-color: var(--container-color);
  text-align: center;
  padding: 0rem 0rem 2rem;
  border-radius: 1rem 1rem 0 0;
  bottom: 0;
  top: 0;
  height: 100%;
  transition: all 1s;

  /*=== Effect 1 ===*/

  /*=== Effect 2 ===*/
  /* transform: scale(.5) translateY(10%); */

  /*=== Effect 3 ===*/
  /* transform: rotateX(65deg) scale(.75) translateY(10%);
  transform-origin: 50% 100%; */
}

.modal__img{
  width: 100%;
  
  margin-top: 0;
  margin-bottom: .75rem;
  border-radius: 1rem 1rem 1rem 1rem ;
}
.slider {
  width:100%;
  border-radius: 1rem 1rem 1rem 1rem  ;
  margin-top: 0;
  position:relative;
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  margin-bottom: 0;
}
.slider img {
  width: 100%;
  height: 300px;
  left: 0;
  position: sticky;
  object-fit: cover;
  border-radius: 5px;
  scroll-snap-align: center;

}

.modal__close{
  display: inline-flex;
  background-color: #FFF;
  border-radius: 1rem;
  color: var(--first-color);
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 999;
}
.modal__close:hover{
  color: var(--first-color-alt);
}

.modal__title{
  font-size: var(--big-font-size);
  color: var(--title-color);
  font-weight: 500;
  margin-left: 2rem;
  margin-right: 2rem;
}

.modal__description{
  margin-left: 2rem;
  margin-right: 2rem;
  align-content: center;
}

.modal__button-width{
  width: 90%;
}

.modal__button-link{
  display: block;
  margin: 1rem auto 0;
  background-color: transparent;
  color: var(--first-color);
  font-weight: 500;
}

/* Show modal */
.show-modal{
  visibility: visible;
  opacity: 1;
}


.show-modal .modal__content{
  transform: translateY(0);


}



 /* Tabs modal */
li {
  list-style-type: none;
}
.contenedor {
  width: 100%;
  max-width: 120em;
  padding: 0.1em;
  padding-top: 0;
  color: var(--negro);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: var(--blanco);
  -webkit-box-shadow: 0.3em 0.3em 0.6em var(--sombra-ppal), -0.3em -0.3em 0.6em var(--sombra-sec), inset 0 0 0 transparent, inset 0 0 0 transparent;
          box-shadow: 0.3em 0.3em 0.6em var(--sombra-ppal), -0.3em -0.3em 0.6em var(--sombra-sec), inset 0 0 0 transparent, inset 0 0 0 transparent;
}

.contenedor .ul {
  width: 100%;
  left: 0rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0rem;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contenedor .li {
  width: 100%;
  border-bottom: solid;
  border-color: #fff;
  padding: 0px;
  margin:0.2em;
  color: var(--negro);
  border-radius: 0rem;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: var(--blanco);
  -webkit-box-shadow: 0.3em 0.3em 0.6em var(--sombra-ppal), -0.3em -0.3em 0.6em var(--sombra-sec), inset 0 0 0 transparent, inset 0 0 0 transparent;
          box-shadow: 0.3em 0.3em 0.6em var(--sombra-ppal), -0.3em -0.3em 0.6em var(--sombra-sec), inset 0 0 0 transparent, inset 0 0 0 transparent;
  cursor: pointer;
}

.contenedor .li.activo {
  border-bottom: solid;
  border-color: #2667ff;
  color: #2667ff;
}

.contenedor .subcontenedor {
  min-height: 9vh;
  position: relative;
}
.contentModal{
  height: 80%;
  align-items: left;
  padding-left: 10%;
  padding-bottom: 10%;
}

.contenedor .bloque {
  color: var(--negro);
  height: 500px;
  margin: 0em;
  padding: 0em;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: var(--blanco);
  -webkit-box-shadow: 0.3em 0.3em 0.6em var(--sombra-ppal), -0.3em -0.3em 0.6em var(--sombra-sec), inset 0 0 0 transparent, inset 0 0 0 transparent;
          box-shadow: 0.3em 0.3em 0.6em var(--sombra-ppal), -0.3em -0.3em 0.6em var(--sombra-sec), inset 0 0 0 transparent, inset 0 0 0 transparent;
  opacity: 0;
  display: none;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.contenedor .bloque.activo {
  top:0;
  display: inline-block;
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.contenedorPrincipalModal {
  padding-top: 0;
  border-radius: 1.25rem;
}
.modal-content{
  border-radius: 1.25rem;
  float: right;
}
.custom-popup {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  
}
.custom-popup img {
  height: auto;
  width: 100%;
  max-width: 300px; 
  margin-top: 0;
  display: block;
  object-fit: cover;
  border-radius: 1rem 1rem 1rem 1rem ;
  display: flex;
  justify-content: center;

}
.text-center{
  padding-top:10px;
  padding-left: 0;
  text-align: left;
}
.leaflet-popup-close-button{
  width: 40px;
}
div.leaflet-popup-content-wrapper{
  margin: 0;
  padding-left: 0;
  box-shadow: 0 3px 14px rgba(0,0,0,0.05);
}
.boton-pop{
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  font-weight: bold;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (min-width: 576px){
  .modal__content{
    margin: auto;
    width: 380px;
    border-radius: 1.25rem;
    height: '85%';
    top: 0;
    transition: all 1s;
    transform: translateY(40%);

  }

  .modal__img{
    width: 100%;
  }
}

