body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loading-map {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  width: 100vw;
  height: 100vh;
  color: white;
}

.search-container{
  position: fixed;
  top: 20px;
  left: 50px;
  background-color: white;
  z-index: 999;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
  width: 250px;
  padding: 5px;
  border-radius: 10px;
}
.pointer {
  cursor: pointer;
}
.leaflet-venue-icon {
  display: none;
}
.leaflet-routing-container {
  display: none;
}

.popup-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 18px;
  margin: 0;
}

.popup-text {
  font-size: 14px;
  margin: 0;
  padding: 0
}

#intro {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 310px;
  font-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  transition: transform 0.3s ease-in-out;
}
.btnTopLeft{
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.05);
  width: 40px;
  padding: 5px;
  border-radius: 10px;
}
.panel__header {
  top: 30px;
  left: 20px;
  margin-top: 30px;

}
.form-group {
  width: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Roboto');

.backLoader {
  width: 100%;
  height: 100%;
  background-color: #263038;
  opacity: 80%;
  z-index: 999;
}
.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  left: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50% 50% 0;
  border: 15px solid #FFF;
  transform: rotate(45deg) translate(0, 0);
  animation: animMarker 0.4s ease-in-out infinite alternate;
}
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150%;
  width: 24px;
  height: 4px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  animation: animShadow 0.4s ease-in-out infinite alternate;
}
.stackEvento{
  width: 100%;
  position: absolute;
}
.stackEventos{
  width: 100% !important;
  display: flex;
  align-items: center; 
  margin-bottom: 1rem;
}
.eventBox {
  width: 100% !important;
  box-shadow: 0;
  padding-left: 2rem;
  padding-right: 2rem;
}
.stackEventos .modal__description {
  flex-grow: 1; /* Esto permite que el span ocupe todo el espacio disponible horizontalmente */
}

.stackEventos .avatar-container {
  display: flex;
  align-items: center; /* Alinear verticalmente el contenido del Avatar */
}
.MuiPaper-root{
  background-color: #edf3f7 !important;
  color: #22232B !important;
  font-family: 'Poppins', sans-serif !important;
}
.MuiButton-root{
  font-family: 'Poppins', sans-serif !important;
}
@keyframes animMarker {
  0% {
    transform: rotate(45deg) translate(5px, 5px);
  }
  100% {
    transform: rotate(45deg) translate(-5px, -5px);
  }
}

@keyframes animShadow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
    
    


::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;}