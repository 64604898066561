/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(228, 81%, 49%);
  --title-color: hsl(228, 12%, 15%);
  --text-color: hsl(228, 8%, 50%);
  --body-color: hsl(228, 100%, 99%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Poppins', sans-serif;
  --normal-font-size: .500rem;
}

/* Responsive typography */
@media screen and (min-width: 968px) {
  :root {
    --normal-font-size: .500rem;
  }
}


/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


body {
  position: relative;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1 {
  color: var(--title-color);
}

a {
  text-decoration: none;
}

/*=============== bar ===============*/
.container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.section {
  padding: 2rem 0;
}
.logo-geo{
  width: 100%; /* Ancho máximo del contenedor */
  max-width: 500px; /* Ancho máximo de la imagen */
  margin: 0 auto;
}

.logo-geo img {
  width: 35px; /* Asegura que la imagen ocupe todo el ancho disponible */
  height: auto; /* La altura se ajustará automáticamente para mantener la relación de aspecto */
}
.chipBar{
  width: 50%;
}
/* Vista CEL */
@media screen and (max-width: 767px) {
  .container {
    margin-left: 7rem;
    margin-right: 1.5rem;
  }
  .bar {
    position: fixed;
    left: 10px;
    top: 10px;
    background-color: var(--container-color);
    box-shadow: 1px 0 4px hsla(228, 81%, 49%, .15);
    width: 65px;
    height: 12vh;
    padding: 1rem;
    border-radius: 30px;
    transition: .3s;
    z-index: 999;
    
    color: #fff;
  }
  .bar__logo {
    display: flex;
  }
  .bar__logo i {
    font-size: 1.25rem;
    color: var(--first-color);
  }
  .bar__logo-name { 
    line-height: 0.1;
    color: var(#fff);
    font-weight: 600;
    padding-left: 1rem;
  }
  .bar__logo, .bar__link {
    align-items: center;
    column-gap: 1rem;
  }
  .bar__list {
    display: grid;
    row-gap: 1rem;
    margin-top: 1rem;
    overflow-y: scroll;
  }
  .bar__content {
    overflow: hidden;
    overflow-y: scroll;
    height: 100%;
  }
  .bar__toggle {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--title-color);
    color: #fff;
    border-radius: 50%;
    font-size: 1.2rem;
    display: grid;
    place-items: center;
    top: 2rem;
    right: -10px;
    cursor: pointer;
    transition: .4s;
  }
}

.bar__link {
  display: flex;
  color: var(--text-color);
  font-weight: 500;
  transition: .3s;
}

.bar__link i {
  font-size: 1.25rem;
}

.bar__link:hover {
  color: var(--first-color);
}

/* Active link */
.active-link {
  color: var(--first-color);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {

}

/* For medium devices */
@media screen and (min-width: 576px) {
 
}

@media screen and (min-width: 767px) {
  .container {
    margin-left: 7rem;
    margin-right: 1.5rem;
  }
  .bar {
    position: fixed;
    left: 0;
    background-color: var(--container-color);
    box-shadow: 1px 0 4px hsla(228, 81%, 49%, .15);
    width: 180px;
    height: 12vh;
    padding: 1rem;
    border-radius: 10px;
    transition: .3s;
    z-index: 999;
  }
  .bar__logo {
    display: flex;
    transition: .3s;
  }
  .bar__logo i {
    font-size: 1.25rem;
    color: var(--first-color);
  }
  .bar__logo-name {
    line-height: 0.5; 
    color: var(--title-color);
    font-weight: 600;
    padding-left: 2rem;
    transition: .4s;
  }
  .bar__logo, .bar__link {
    transition: .4s;
    align-items: center;
    column-gap: 1rem;
  }
  .bar__list {
    display: grid;
    row-gap: 1rem;
    margin-top: 1rem;
    overflow-y: scroll;
  }
  .bar__content {
    overflow: hidden;
    overflow-y: scroll;
    height: 100%;
  }
  .bar__toggle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--title-color);
    color: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    top: 2rem;
    right: -10px;
    cursor: pointer;
    transition: .4s;
    
  }
}

/* Show menu */
.show-menu {
  width: 455px;
  height: 100vh;
  color: hsl(228, 12%, 15%);
  --title-color: hsl(228, 12%, 15%);
}
.bar_descripcion{
  width: 60%;
}
.bar__img{
  width:40%
}
.bar_container{
  display: flex;
}
.bar_button {
  margin-top: 20px;
  margin-left: 15px ;
  border-radius: 40px;
}
/* Rotate toggle icon */
.rotate-icon {
  transform: rotate(180deg);
}

/* For 2K & 4K resolutions */
@media screen and (min-width: 2048px) {

}

@media screen and (min-width: 3840px) {

}